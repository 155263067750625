import React from 'react';
import { Heading } from 'paypo-ui/components';

import { SItemsContainer } from './styles';

import Section from '../../layout/Section';
import OrderedItem from './OrderedItem';
import SquareGenerator from '../../molecules/SquareGenerator';

const SectionOrderedItems = ({
    config,
    itemsReversed = false,
    background = '',
    columns = 2,
    isColorSectionAbove = false,
    isColorSectionBelow = false,
}) => {
    const {
        title,
        id,
        itemList,
        squares,
        forwardedRef,
        navigationRef,
    } = config;

    const handleColumnClass = columns => {
        switch (columns) {
            case 1:
                return 'one-column';
            case 3:
                return 'three-columns';
            case 2:
            default:
                return 'two-columns';
        }
    };


    return (
        <Section
            background={ background }
            forwardedRef={ forwardedRef }
            navigationRef={ navigationRef }
            id={ id }
            isColorSectionAbove={ isColorSectionAbove }
            isColorSectionBelow={ isColorSectionBelow }
        >
            <Heading className='mb-m-xl'>
                { title }
            </Heading>
            <SItemsContainer itemsReversed={ itemsReversed }>
                {
                    itemList.map( item => (
                        <OrderedItem
                            key={ item.id }
                            itemData={ item }
                            itemColumnClass={ handleColumnClass(columns) }
                        />
                    ))
                }
            </SItemsContainer>
            {
                !!squares && <SquareGenerator squares={ squares }/>
            }
        </Section>
    );
};

export default SectionOrderedItems;
