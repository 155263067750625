import styled from 'styled-components';

import { breakpoints, colors } from  '../../../styles/variables';
import { SH3 } from '../../atoms/Typography/H3/styles';
import { SText } from '../../atoms/Typography/Text/styles';

const handleTextSize = size => {
    switch (size) {
        case 'small':
            return `2.4rem`;
        case 'normal':
        default:
            return `3.6rem`;
    }
};

export const HeroContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;

    @media (min-width: ${ breakpoints.medium }) {
        justify-content: space-between;
        flex-direction: row;
        align-items: flex-start;
    }

    @media (min-width: ${ breakpoints.big }) {
        max-width: 100%;
    }
`;

export const HeroContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 54rem;

    @media (min-width: ${ breakpoints.medium }) {
        align-items: flex-start;
        max-width: 70rem;
    }
`;

export const HeroSubheader = styled(SText)`
    font-family: "Gilroy", sans-serif;
    font-weight: 800;
    max-width: 50rem;
    margin-bottom: 1.6rem;
    font-size: 2.4rem;
    line-height: 3.2rem;
    letter-spacing: -1px;
    text-align: center;

    @media (min-width: ${ breakpoints.medium }) {
        margin-bottom: 2.4rem;
        text-align: left;
        font-size: 2.4rem;
        line-height: 3.2rem;
        max-width: 62rem;
    }
`;

export const HeroText = styled(SText)`
    font-family: "Gilroy", sans-serif;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2.4rem;
    letter-spacing: -.3px;
    text-align: center;
    margin: 0;
    display: inline;

    @media (min-width: ${ breakpoints.medium }) {
        text-align: left;
        display: block;
    }
`;

export const HeroGraphic = styled.div`
    position: relative;
    display: flex;
    flex-shrink: 0;
    width: 40rem;
    height: 40rem;

    &.additional-space {
        height: 44rem;
    }
    
    @media (min-width: ${ breakpoints.medium }) {
        margin-top: -2rem;
    }

    @media (min-width: ${ breakpoints.large }) {
        width: 60rem;
        height: 60rem;

        &.additional-space {
            height: 64rem;
        }
    }
`;

export const GraphicBox = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

export const HeroPromoText = styled(SH3)`
    margin-bottom: 1.6rem;
    text-align: center;
    color: ${
        ({ promoTextColor }) => promoTextColor || colors.blackHighContrast
    };

    font-size: ${
        ({ size }) => handleTextSize(size)
    };

    @media (min-width: ${ breakpoints.medium }) {
        margin-bottom: 2.4rem;
        text-align: left;
    };

    span {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 3.6rem;
        height: 3.6rem;
        background-color: ${ colors.paypoGreen600 };
        color: ${ colors.white };

        @media (min-width: ${ breakpoints.medium }) {
            width: 5rem;
            height: 5rem;
        }
    };
`;
