import React, { useState, useEffect, useRef } from 'react';

import {
    SCard,
    SContent,
    SContentContainer
} from './styles';

import CardHeader from './CardHeader';

const CollapsingCard = ({
    id,
    header,
    children,
    inAccordion = false,
    expanded = false,
    isConcurrentMode = false,
    transmitCardId = () => {},
    transmitOpenCardId = () => {},
    ...props
}) => {
    const [ isCardExpanded, setIsCardExpanded ] = useState(expanded);
    const [ contentHeight, setContentHeight ] = useState(0);

    const contentRef = useRef(null);

    useEffect(() => {
        contentRef.current && setContentHeight(contentRef.current.clientHeight);
    }, []);

    useEffect(() => {
        inAccordion && transmitCardId(contentRef.current.id);
    }, [inAccordion, transmitCardId]);

    useEffect(() => {
        setIsCardExpanded(expanded);
    }, [expanded]);
    
    const handleCardExpand = () => {
        if (inAccordion && isConcurrentMode) {
            transmitOpenCardId(
                isCardExpanded ? '' : contentRef.current.id
            );
        } else {
            setIsCardExpanded(!isCardExpanded);
        }
    };

    return (
        <SCard { ...props }>
            <CardHeader
                header={ header }
                handleCardExpand={ handleCardExpand }
                isCardExpanded={ isCardExpanded }
            />
            <SContentContainer
                isCardExpanded={ isCardExpanded }
                contentHeight={ contentHeight }
            >
                <SContent
                    id={ id }
                    ref={ contentRef }
                    isCardExpanded={ isCardExpanded }
                >
                    { children }
                </SContent>
            </SContentContainer>
        </SCard>
    );
};

export default CollapsingCard;
