import React from 'react';
import {
    getImage, 
    GatsbyImage,
} from "gatsby-plugin-image";

import { useStaticQuery, graphql } from "gatsby";

import * as S from './styles';

const SmartplanHeroGraphic = () => {
    const heroImage = useStaticQuery(
        graphql`
            {
                file(relativePath: { eq: "pn3_hero_graphic_600x600.png" }) {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
            }
        `
    );

    const image = getImage(heroImage.file);

    return (
        <S.HeroGraphic>
            <S.GraphicBox>
                <GatsbyImage
                    image={ image }
                    alt="hero image"
                />
            </S.GraphicBox>
        </S.HeroGraphic>
    );
};

export default SmartplanHeroGraphic;
