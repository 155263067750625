import styled from 'styled-components';

import { colors, breakpoints } from '../../../styles/variables';
import { SText } from '../../atoms/Typography/Text/styles';
import { H3 as SH3 } from '../section-popup-ordered-items/styles';

export const H3 = styled(SH3)``;

export const TilesContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 3rem;
    row-gap: 1.6rem;
    padding-top: 1.6rem;
    width: 100%;

    @media (min-width: ${ breakpoints.popupMedium }) {
        grid-template-columns: 1fr 1fr;
        row-gap: 2.4rem;
    }
`;

export const TileItem = styled.div`
    background-color: ${
        ({ background }) => background || colors.white
    };

    border-radius: 1.6rem;
    padding: 2.4rem 2.4rem 7.2rem;
    box-sizing: border-box;
    position: relative;

    @media (min-width: ${ breakpoints.popupMedium }) {
        padding-bottom: 5.6rem;
    }
`;

export const TileTitle = styled(SText)`
    font-family: 'Gilroy', sans-serif;
    display: flex;
    font-weight: 800;
    margin-bottom: 1.6rem;
    color: ${ colors.blackLowContrast };
`;

export const TileText = styled(SText)`
    font-family: 'Gilroy', sans-serif;
    display: flex;
    font-size: 1.4rem;
    line-height: 2rem;
    margin-bottom: 2.4rem;
    color: ${ colors.blackLowContrast };
`;

export const CtaContainer = styled.div`
    position: absolute;
    left: 2.4rem;
    bottom: 2.4rem;
    right: 2.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 4.2rem;

    a {
        font-size: 1.4rem;
        width: 100%;
    }

    @media (min-width: ${ breakpoints.popupMedium }) {
        justify-content: flex-start;
        align-items: flex-start;
        min-height: 3.2rem;

        a {
            padding: .8rem 2.8rem;
            width: initial;
        }
    }
`;
