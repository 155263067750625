import React from 'react';

import {
    SStartSlideContainer,
    SStartSlideText,
    StartSlideFooter,
    SSlideAuthor,
    SSlideScore,
} from './styles';

const StartSlideTemplate = ({ slideData }) => {
    const {
        text,
        score,
        name,
    } = slideData;

    return (
        <SStartSlideContainer>
            <SStartSlideText>
                { text }
            </SStartSlideText>
            <StartSlideFooter>
                <SSlideAuthor>
                    { name }
                </SSlideAuthor>
                <SSlideScore>
                    { score }
                </SSlideScore>
            </StartSlideFooter>
        </SStartSlideContainer>
    );
};

export default StartSlideTemplate;
