import styled from "styled-components";

import { colors, breakpoints } from  '../../../styles/variables';

export const ItemsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 25rem;
    max-width: 100%;
    flex-wrap: wrap;
    align-items: flex-start;

    @media (min-width: ${ breakpoints.medium }) {
        width: 56rem;
    }

    @media (min-width: ${ breakpoints.large }) {
        width: 100%;
    }
`;

export const Item = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1.5rem;
    padding: 0 1rem;
    box-sizing: border-box;
    width: 100%;

    &.two-columns {
        @media (min-width: ${ breakpoints.medium }) {
            width: 50%;
        }
    }

    &.three-columns {
        @media (min-width: ${ breakpoints.medium }) {
            width: 50%;
        }

        @media (min-width: ${ breakpoints.large }) {
            width: 33%;
        }
    }

    &.four-columns {
        @media (min-width: ${ breakpoints.medium }) {
            width: 50%;
        }

        @media (min-width: ${ breakpoints.large }) {
            width: 25%;
        }
    }
`;

export const IconContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    .svg-icon {
        height: 110px;
        margin-bottom: 15px;
        box-sizing: border-box;
    }
`;

export const ItemDescription = styled.p`
    width: 100%;
    max-width: 100%;
    text-align: center;
    font-family: 'Gilroy', sans-serif;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: ${ colors.blackHighContrast };

    span {
        font-weight: 800;
    }
`;

export const AdditionalText = styled.p`
    font-family: 'Gilroy', sans-serif;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: ${ colors.blackLowContrast };
    margin: 0;
    padding-top: 2.4rem;
    letter-spacing: -.03rem;

    &:first-of-type {
        padding-top: 3.2rem;
    }

    a {
        color: ${ colors.paypoGreen600 };
        text-decoration: none;
    }
`;
