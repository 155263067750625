export const checkAppearanceInViewport = (elementRef, callbackFunction) => {
    !!window && window.addEventListener("scroll", () => {
        const windowCentralAreaTop = window.pageYOffset;
        const windowCentralAreaEnd = window.pageYOffset + window.innerHeight - 300;
        const elementOffsetTop = elementRef.current.offsetTop;
    
        if (
            windowCentralAreaEnd > elementOffsetTop &&
            windowCentralAreaTop < elementOffsetTop
        ) {
            callbackFunction(elementRef.current.id);
        }
    });
};
