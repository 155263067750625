import React from 'react';

import * as S from './styles';

const IconItem = ({
    Icon,
    description,
    itemColumnClass,
}) => {
    return (
        <S.Item className={ itemColumnClass }>
            <S.IconContainer>
                <Icon className="svg-icon" />
            </S.IconContainer>
            
            <S.ItemDescription
                dangerouslySetInnerHTML={{ __html: description }}
            />
        </S.Item>
    );
};

export default IconItem;
