import styled from 'styled-components';

import { breakpoints } from '../../../styles/variables';

export const SSquare = styled.div`
    ${({ squareData }) => {
        const {
            left,
            right,
            top,
            bottom,
            size,
            bgColor,
            rotate,
            zIndex,
        } = squareData;

        return `
            position: absolute;
            display: none;
            ${ left ? `left: ${ left };` : '' }
            ${ right ? `right: ${ right };` : '' }
            ${ top ? `top: ${ top };` : '' }
            ${ bottom ? `bottom: ${ bottom };` : '' }
            width: ${ size };
            height: ${ size };
            background-color: ${ bgColor };
            transform: rotate(${ rotate });
            z-index: ${ zIndex || -1 };
        `;
    }}

    @media (min-width: ${ breakpoints.medium }) {
        display: block;
    }
`;
