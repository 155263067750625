import styled from 'styled-components';

import { colors, breakpoints } from '../../../styles/variables';
import { SText } from '../../atoms/Typography/Text/styles';

const appCountry = process.env.GATSBY_APP_COUNTRY;

export const STilesContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 3rem;
    row-gap: 1.6rem;
    width: 100%;

    @media (min-width: ${ breakpoints.medium }) {
        grid-template-columns: 1fr 1fr;
        row-gap: 2.4rem;
    }
`;

export const STileItem = styled.div`
    border-radius: 1.6rem;
    box-sizing: border-box;
    position: relative;

    background-color: ${
        ({ background }) => background || colors.white
    };

    padding: ${
        appCountry === 'RO' ? `2.4rem` : `2.4rem 2.4rem 6.6rem`
    };

    @media (min-width: ${ breakpoints.medium }) {
        padding: ${
            appCountry === 'RO' ? `4rem` : `4rem 4rem 8.2rem`
        };
    }
`;

export const STileText = styled(SText)`
    font-family: 'Gilroy', sans-serif;
    display: flex;
    margin: 0;
    color: ${ colors.blackLowContrast };

    margin: ${
        appCountry === 'RO' ? `0` : `0 0 2.4rem 0`
    };
`;

export const SCtaContainer = styled.div`
    position: absolute;
    left: 2.4rem;
    bottom: 2.4rem;
    right: 2.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 4.2rem;

    @media (min-width: ${ breakpoints.medium }) {
        left: 4rem;
        bottom: 4rem;
        right: 4rem;
        justify-content: flex-start;
        align-items: flex-start;
    }
`;
