import React from 'react';

const moveToSection = sectionRef => sectionRef.current.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
});

// Navbar

const navbarRef = {};

navbarRef.forwardedRef = React.createRef();
navbarRef.navigationRef = React.createRef();
navbarRef.moveToSection = () => moveToSection(navbarRef.forwardedRef);

// Hero

const heroRef = {};

heroRef.forwardedRef = React.createRef();
heroRef.navigationRef = React.createRef();
heroRef.moveToSection = () => moveToSection(heroRef.forwardedRef);

// SectionHowItWorks

const howItWorksRef = {};

howItWorksRef.forwardedRef = React.createRef();
howItWorksRef.navigationRef = React.createRef();
howItWorksRef.moveToSection = () => moveToSection(howItWorksRef.forwardedRef);

// SectionBenefits

const benefitsRef = {};

benefitsRef.forwardedRef = React.createRef();
benefitsRef.navigationRef = React.createRef();
benefitsRef.moveToSection = () => moveToSection(benefitsRef.forwardedRef);

// SectionFaq

const faqRef = {};

faqRef.forwardedRef = React.createRef();
faqRef.navigationRef = React.createRef();
faqRef.moveToSection = () => moveToSection(faqRef.forwardedRef);

// SectionOpinions

const opinionsRef = {};

opinionsRef.forwardedRef = React.createRef();
opinionsRef.navigationRef = React.createRef();
opinionsRef.moveToSection = () => moveToSection(opinionsRef.forwardedRef);

// SectionContact

const contactRef = {};

contactRef.forwardedRef = React.createRef();
contactRef.navigationRef = React.createRef();
contactRef.moveToSection = () => moveToSection(contactRef.forwardedRef);

export {
    heroRef,
    navbarRef,
    howItWorksRef,
    benefitsRef,
    faqRef,
    opinionsRef,
    contactRef,
};
