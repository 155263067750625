import styled from 'styled-components';

import { colors, breakpoints } from '../../../styles/variables';

export const SCard = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: .8rem;
    border-radius: 1.6rem;
    border: 1px solid ${ colors.silver };

    &:hover {
        border: 1px solid ${ colors.paypoGreen500 };
    }
`;

export const SContent = styled.div`
    display: flex;
    flex-direction: column;
    transition: .2s;
    position: relative;
    bottom: 3rem;
    opacity: ${
        props => props.isCardExpanded ? 1 : 0
    };
`;

export const SContentContainer = styled.div`
    overflow: visible;
    height: ${
        props => props.isCardExpanded ? `${props.contentHeight - 30}px` : 0
    };
    transition: .2s;
`;

export const SHeaderIcon = styled.div`
    width: 4rem;
    height: 2.8rem;
    display: flex;
    flex-shrink: 0;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        transition: .2s;
        background-color: ${
            props => props.isCardExpanded ? colors.paypoGreen600 : colors.black
        };
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(
            ${ props => props.isCardExpanded ? '-90deg' : '0' }
        );
        width: .2rem;
        height: 1.6rem;
    }

    &:after {
        content: '';
        position: absolute;
        transition: .2s;
        background-color: ${
            props => props.isCardExpanded ? colors.paypoGreen600 : colors.black
        };
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 1.6rem;
        height: .2rem;
    }
`;

export const SHeader = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    cursor: pointer;
    padding: 2.4rem;
    z-index: 2;

    @media (min-width: ${ breakpoints.medium }) {
        padding: 4rem;
    }
`;

export const SHeaderTitle = styled.p`
    margin: 0;
    font-family: 'Gilroy', sans-serif;
    font-weight: 800;
    font-size: 1.6rem;
    line-height: 2.4rem;
    letter-spacing: -0.5px;
    transition: .2s;
    color: ${
        props => props.isCardExpanded ? colors.paypoGreen600 : colors.black
    };

    @media (min-width: ${ breakpoints.medium }) {
        font-size: 2rem;
        line-height: 2.8rem;
    }
`;
