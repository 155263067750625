import React from 'react';
import { Heading } from 'paypo-ui/components'

import * as S from './styles';

import Section from '../../layout/Section';
import OrderedItem from './OrderedItem';

const SectionPopupOrderedItems = ({
    config,
    itemsReversed = false,
    background = '',
    columns = 2,
    isColorSectionAbove = false,
    isColorSectionBelow = false,
}) => {
    const {
        title,
        id,
        itemList,
        forwardedRef,
        navigationRef,
    } = config;

    const handleColumnClass = columns => {
        switch (columns) {
            case 1:
                return 'one-column';
            case 3:
                return 'three-columns';
            case 2:
            default:
                return 'two-columns';
        }
    };

    return (
        <Section
            background={ background }
            forwardedRef={ forwardedRef }
            navigationRef={ navigationRef }
            id={ id }
            isColorSectionAbove={ isColorSectionAbove }
            isColorSectionBelow={ isColorSectionBelow }
            popupSection
        >
            <Heading variant='H3'>
                { title }
            </Heading>

            <S.ItemsContainer itemsReversed={ itemsReversed }>
                {
                    itemList.map( item => (
                        <OrderedItem
                            key={ item.id }
                            itemData={ item }
                            itemColumnClass={ handleColumnClass(columns) }
                        />
                    ))
                }
            </S.ItemsContainer>
        </Section>
    );
};

export default SectionPopupOrderedItems;
