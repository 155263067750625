import React from 'react';

import StartHeroGraphic from './StartHeroGraphic';
import Raty0HeroGraphic from './Raty0HeroGraphic';
import PSPHeroGraphic from './PSPHeroGraphic';
import SmartplanHeroGraphic from './SmartplanHeroGraphic';
import Pn4HeroGrahpic from './Pn4HeroGraphic';
import ClientiHeroGraphic from './ClientiHeroGraphic';

const HeroGraphic = ({ graphicName }) => {
    return (
        <>
            {
                graphicName === "start" &&
                    <StartHeroGraphic />
            }

            {
                graphicName === "raty0" &&
                    <Raty0HeroGraphic />
            }

            {
                graphicName === "psp" &&
                    <PSPHeroGraphic />
            }

            {
                graphicName === "smartplan" &&
                    <SmartplanHeroGraphic />
            }

            {
                graphicName === "pn4" &&
                    <Pn4HeroGrahpic />
            }

            {
                graphicName === "clienti" &&
                    <ClientiHeroGraphic />
            }
        </>
    );
};

export default HeroGraphic;
