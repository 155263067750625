import React from 'react';
import { Heading } from 'paypo-ui/components';

import {
    STilesContainer
} from './styles';

import Section from '../../layout/Section';
import SquareGenerator from '../../molecules/SquareGenerator';
import TileItem from './TileItem';

const SectionBigTiles = ({
    config,
    background = '',
    isColorSectionAbove = false,
    isColorSectionBelow = false,
}) => {
    const {
        title,
        id,
        tileList,
        squares,
        forwardedRef,
        navigationRef,
    } = config;

    return (
        <Section
            background={ background }
            forwardedRef={ forwardedRef }
            navigationRef={ navigationRef }
            id={ id }
            isColorSectionAbove={ isColorSectionAbove }
            isColorSectionBelow={ isColorSectionBelow }
        >
            <Heading
                variant="H2"
                styleVariant="L"
                className='mb-m-xl'
            >
                { title }
            </Heading>

            <STilesContainer>
                {
                    tileList.map( tile => (
                        <TileItem
                            key={ tile.id }
                            tileData={ tile }
                        />
                    ))
                }
            </STilesContainer>
            {
                !!squares && <SquareGenerator squares={ squares }/>
            }
        </Section>
    );
};

export default SectionBigTiles;
