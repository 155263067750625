import styled from 'styled-components';

import { colors, breakpoints } from '../../../styles/variables';

export const SHeaderWrapper = styled.div`
    max-width: 60rem;
`;

export const SAccordionContainer = styled.div`
    max-width: 73rem;
`;

export const SCardContent = styled.p`
    margin: 0;
    padding: 0 2.4rem 2.4rem;
    font-family: 'Gilroy', sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.3px;
    color: ${ colors.blackLowContrast };

    @media (min-width: ${ breakpoints.medium }) {
        padding: 0 4rem 4rem;
    }
`;
