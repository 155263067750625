import styled from "styled-components";

import { breakpoints, colors } from '../../../../styles/variables';

export const SH3 = styled.h3`
    font-family: 'Gilroy', sans-serif;
    font-weight: 800;
    font-size: 2.4rem;
    line-height: 4.4rem;
    position: relative;
    margin: 0 0 1.6rem;
    z-index: 1;
    color: ${ colors.blackHighContrast };

    @media (min-width: ${ breakpoints.medium }) {
        font-size: 3rem;
    }

    @media (min-width: ${ breakpoints.big }) {
        font-size: 3.6rem;
    }
`;
