import React from 'react';
import { Button } from 'paypo-ui/components';

import * as S from './styles';

const TileItem = ({ tileData }) => {
    const { bgColor, content } = tileData;
    const { title, textContent, cta } = content;
    const {
        text: ctaText,
        type: ctaType,
        action: ctaAction,
    } = cta;

    let hrefByCtaAction;

    switch (ctaAction) {
        case 'email':
            hrefByCtaAction = `mailto:${ title }`;
            break;
        case 'phoneCall':
            hrefByCtaAction = `tel:${ title.replace(/\s+/g, "") }`;
            break;
        default:
            hrefByCtaAction = null;
    }

    return (
        <S.TileItem background={ bgColor }>
            <S.TileTitle>
                { title }
            </S.TileTitle>

            {
                !!textContent && (
                    textContent.map(({ 
                        text, 
                        id, 
                    }) => (
                        <S.TileText key={ id }>
                            { text }
                        </S.TileText>
                    ))
                )
            }

            <S.CtaContainer>
                <Button
                    type={ ctaType }
                    linkTo={ hrefByCtaAction }
                >
                    { ctaText }
                </Button>
            </S.CtaContainer>
        </S.TileItem>
    );
};

export default TileItem;
