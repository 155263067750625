import React from 'react';
import { Button, Heading } from 'paypo-ui/components';

import * as S from './styles';

import Section from '../../layout/Section';
import IconItem from './IconItem';

const SectionPopupIconItems = ({
    config,
    background = '',
    isColorSectionAbove = false,
    isColorSectionBelow = false,
}) => {
    const {
        title,
        id,
        content,
        additionalText,
        cta,
        handleCta,
        forwardedRef,
        navigationRef,
    } = config;

    return (
        <Section
            background={ background }
            forwardedRef={ forwardedRef }
            navigationRef={ navigationRef }
            id={ id }
            isColorSectionAbove={ isColorSectionAbove }
            isColorSectionBelow={ isColorSectionBelow }
            popupSection
        >
            <Heading variant='H3'>
                { title }
            </Heading>

            <S.ItemsContainer>
                {
                    content.map(({
                        Icon,
                        description
                    }, index)=> (
                        <IconItem
                            key={ index }
                            Icon={ Icon }
                            description={ description }
                        />
                    ))
                }
            </S.ItemsContainer>

            {
                additionalText ? (
                    additionalText.map(({ text }) => (
                        <S.SlideAdditionalText dangerouslySetInnerHTML={ { __html: text } } />
                    ))
                ) : null
            }
            
            {
                cta && (
                    <Button onClick={ handleCta }>
                        { cta }
                    </Button>
                )
            }
        </Section>
    );
};

export default SectionPopupIconItems;
