import React, { useState, createContext } from 'react';

export const SectionAppearanceContext = createContext();

export const SectionAppearanceProvider = ({ children }) => {
    const [ activeSection, setActiveSection ] = useState();

    return (
        <SectionAppearanceContext.Provider
            value={[ activeSection, setActiveSection ]}
        >
            { children }
        </SectionAppearanceContext.Provider>
    );
};

