import styled from "styled-components";
import { colors, breakpoints,  } from '../../../styles/variables';
import { SText } from '../../../components/atoms/Typography/Text/styles';

export const SHeaderContainer = styled.div`
    width: 100%;
    max-width: 84rem;
    margin: auto auto 3rem;

    p {
        margin-top: 2.4rem;
    }

    @media (min-width: ${ breakpoints.medium }) {
        margin: 0 3rem 6rem;
    }
`;

export const SLink = styled.a`
    font-family: 'Gilroy', sans-serif;
    font-weight: 800;
    color: ${ colors.greenPersian };
    font-size: 1.6rem;

    &:hover {
        text-decoration: underline;
    }
`;

export const SSliderWrapper = styled.div`
    position: relative;
    margin-bottom: 2.8rem;
    z-index: 5;
    padding-left: 1.8rem;

    .swiper-wrapper {
        position: relative;
        display: flex;
        width: 100%;
        box-sizing: content-box;
        padding: .4rem 0;
    }

    .slider {
        width: calc(100vw - 40px);
        padding-right: 25px;
        overflow: visible;
        display: flex;
      
        @media (min-width: ${ breakpoints.medium }) {
          width: 72rem;
          overflow: hidden;
          padding: 0;
        }
      
        @media (min-width: ${ breakpoints.big }) {
          width: 80rem;
        }
      
        @media (min-width: ${ breakpoints.large }) {
          width: 90rem;
        }

        @media (min-width: 1440px) {
            width: 114rem;
        }
    }

    .slide {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        height: auto;
        min-height: 26rem;
        width: 22rem;
        position: relative;
        text-align: left;
        padding: 0;
        border: 1px solid $color-pink-amour;

        div {
            background-color: ${ colors.white };
        }

        @media (min-width: ${ breakpoints.medium }) {
            min-height: 24rem;
            width: 36rem;
            padding: 0 .4rem;
        }
    }

    .quoteMarkOpen {
        position: absolute;
        top: 84px;
        left: 12px;
    }
    
    .quoteMarkClose {
        position: absolute;
        width: 60px;
        right: 12px;
        bottom: 70px;
    }
      
    .arrowIcon {
        width: 26px;
    }
      
    .arrowIconInverted {
        transform: rotate(180deg);
    }

    @media (min-width: ${ breakpoints.medium }) {
        margin-bottom: 4rem;
        padding-left: 0;
    }
`;

export const SLogoContainer = styled.div`
    height: 5rem;
    width: 100%;
    object-fit: contain;
    object-position: left;
    margin-bottom: 3rem;
`;

export const SLogoImage = styled.img`
    display: flex;
    max-height: 100%;
    height: 100%;
`;

export const SSlideDescription = styled.p`
    font-size: 1.6rem;
    font-family: "Helvetica", sans-serif;
    line-height: 2rem;
    color: ${ colors.black };
`;

export const SAuthor = styled.p`
    font-family: "Helvetica", sans-serif;
    font-size: 1.2rem;
    position: absolute;
    padding: 0 2rem;
    bottom: 0;
    left: 0;
    width: 100%;
    font-weight: bold;
    text-align: right;
    color: ${ colors.black };
`;

export const SSlideButton = styled.button`
    visibility: ${ ({ hide }) => hide ? 'hidden' : 'visible'};
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    width: 50px;
    height: 50px;
    background-color: ${ colors.secondary };
    border: none;
    cursor: pointer;
`;

export const SSlideButtonPrev = styled(SSlideButton)`
    left: -1rem;

    @media (min-width: ${ breakpoints.big }) {
        left: -7rem;
    }
`;

export const SSlideButtonNext = styled(SSlideButton)`
    right: -1rem;

    @media (min-width: ${ breakpoints.big }) {
        right: -7rem;
    }
`;

export const SStartSlideContainer = styled.div`
    width: 100%;
    height: 100%;
    box-shadow: ${ colors.paypoShadowGrey01 };
    border-radius: 1.6rem;
    padding: 2.4rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (min-width: ${ breakpoints.medium }) {
        padding: 4rem;
    }
`;

export const SStartSlideText = styled(SText)`
    font-family: "Gilroy", sans-serif;
    font-weight: 600;
    color: ${ colors.blackLowContrast };
`;

export const StartSlideFooter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const SSlideAuthor = styled.p`
    margin: 0;
    font-family: "Gilroy", sans-serif;
    font-weight: 800;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: ${ colors.black };
`;

export const SSlideScore = styled.p`
    margin: 0;
    font-family: "Gilroy", sans-serif;
    font-weight: 800;
    font-size: 2.4rem;
    line-height: 3.2rem;
    color: ${ colors.paypoGreen600 };
`;

export const SlideAdditionalText = styled.p`
    font-family: 'Gilroy', sans-serif;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: ${ colors.blackLowContrast };
    margin: 0;
    padding-bottom: 2.4rem;
    letter-spacing: -.03rem;

    &:last-of-type {
        padding-bottom: 4rem;
    }

    a {
        color: ${ colors.paypoGreen600 };
        text-decoration: none;
    }
`;
