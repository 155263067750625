import React from "react";

import { SResponsiveVideo } from './styles';

const YoutubePlayer = ({ videoId }) => (
    <SResponsiveVideo>
        <iframe
            width="853"
            height="480"
            src={`https://www.youtube-nocookie.com/embed/${ videoId }?rel=0&hl=${ process.env.GATSBY_APP_COUNTRY }`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
        />
    </SResponsiveVideo>
);

export default YoutubePlayer;
