import React from 'react';

import {
    SSection,
    SSectionContent,
    SReferenceAnchor,
} from './styles';

const Section = ({
    children,
    background,
    forwardedRef,
    navigationRef,
    id,
    isColorSectionAbove = false,
    isColorSectionBelow = false,
    popupSection = false,
    alignItems = 'center',
}) => {
    const isDoublePaddingTop = !!background || isColorSectionAbove;
    const isDoublePaddingBottom = !!background || isColorSectionBelow;

    return (
        <SSection
            background={ background }
            isDoublePaddingTop={ isDoublePaddingTop }
            isDoublePaddingBottom={ isDoublePaddingBottom }
            id={ id }
            ref={ navigationRef }
            popupSection={ popupSection }
        >
            <SSectionContent alignItems={ alignItems }>
                { children }
            </SSectionContent>
            <SReferenceAnchor
                id={ id }
                ref={ forwardedRef }
            ></SReferenceAnchor>
        </SSection>
    );
};

export default Section;
