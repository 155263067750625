import React from 'react';

import {
    SVideoSection,
    SVideoContainer,
} from './styles';

import YoutubePlayer from '../../organisms/YoutubePlayer';
import SquareGenerator from '../../molecules/SquareGenerator';

const SectionVideoContainer = ({
    background = '',
    backgroundBottom = '',
    config,
}) => {
    const { videoId, squares } = config;

    return (
        <SVideoSection
            background={ background }
            backgroundBottom={ backgroundBottom }
        >
            <SVideoContainer>
                <YoutubePlayer videoId={ videoId } />
            </SVideoContainer>

            {
                !!squares && (
                    <SquareGenerator squares={ squares } />
                )
            }
        </SVideoSection>
    );
};

export default SectionVideoContainer;
