import React from 'react';

import {
    SHeader,
    SHeaderIcon,
    SHeaderTitle
} from './styles';

const CardHeader = ({
    header,
    isCardExpanded,
    handleCardExpand
}) => {
    const handleClick = () => handleCardExpand();

    return (
        <SHeader
            isCardExpanded={ isCardExpanded }
            onClick={ handleClick }
        >
            <SHeaderTitle isCardExpanded={ isCardExpanded }>
                { header }
            </SHeaderTitle>
            <SHeaderIcon isCardExpanded={ isCardExpanded } />
        </SHeader>
    );
};

export default CardHeader;
