import React from 'react';
import { Button, Heading } from 'paypo-ui/components';

import {
    STileItem,
    STileText,
    SCtaContainer,
} from './styles';

const TileItem = ({ tileData }) => {
    const { bgColor, content } = tileData;
    const { title, textContent, cta } = content;

    let hrefByCtaAction;

    switch (cta?.action) {
        case 'email':
            hrefByCtaAction = `mailto:${ title }`;
            break;
        case 'phoneCall':
            hrefByCtaAction = `tel:${ title.replace(/\s+/g, "") }`;
            break;
        default:
            hrefByCtaAction = null;
    }

    return (
        <STileItem background={ bgColor }>
            {
                Array.isArray(title) ? (
                    title.map((element, index) => (
                        <Heading
                            variant='H3'
                            key={ index }
                            styleVariant="M"
                            textAlign="left"
                            className="mb-xs"
                        >
                            { element }
                        </Heading>
                    ))
                ) : (
                    <Heading
                        variant='H3'
                        styleVariant="M"
                        textAlign="left"
                        className="mb-xs"
                    >
                        { title }
                    </Heading>
                )
            }

            {
                !!textContent && (
                    textContent.map(({ 
                        text,
                        id, 
                    }) => (
                        <STileText key={ id }>
                            { text }
                        </STileText>
                    ))
                )
            }

            {
                !!cta && (
                    <SCtaContainer>
                        <Button
                            type={ cta?.type }
                            variant={ cta?.variant }
                            linkTo={ hrefByCtaAction }
                        >
                            { cta?.text }
                        </Button>
                    </SCtaContainer>
                )
            }
        </STileItem>
    );
};

export default TileItem;
