import React from 'react';

import SquareTemplate from './SquareTemplate';

const SquareGenerator = ({ squares }) => {
    return (
        squares.map( (squareData, index) => (
            <SquareTemplate
                squareData={ squareData }
                key={ index }
            />
        ))
    );
};

export default SquareGenerator;
