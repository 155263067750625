import styled from "styled-components";
import { colors } from '../../../../styles/variables';

const handleTextSize = ({ size }) => {
    switch (size) {
        case 'extra-small':
            return `5rem`;
        case 'very-small':
            return `6rem`;
        case 'small':
            return `7rem`;
        case 'large':
            return `9rem`;
        case 'normal':
        default:
            return `9rem`;
    }
};

const secondTextStyles = `
    position: absolute;
    z-index: -1;
    left: 5px;
    bottom: 4px;
    -webkit-text-stroke: 0;
    -webkit-text-fill-color: ${ colors.paypoGreen500 };
`;

export const STextBackgroundLayer = styled.span`
    ${ secondTextStyles }
    bottom: ${({ hasPrefix }) => hasPrefix ? '.5rem' : '0'};
`;

export const STextStrokeLayer = styled.div`
    position: relative;
    z-index: 1;
    display: inline-block;
    font-size: ${ props => handleTextSize(props) };
    stroke: ${ colors.black };
    -webkit-text-stroke: 1px ${ colors.black };
    -webkit-text-fill-color: transparent;
    font-family: "Gilroy", sans-serif;
    font-weight: 800;
    line-height: 1;

    bottom: ${
        ({ hasPrefix }) => hasPrefix ? '.5rem' : '0'
    };

    margin-right: ${
        ({ hasNoSuffixSpace }) => hasNoSuffixSpace ? '0' : '1rem'
    };

    .second-text {
        ${ secondTextStyles }
    }
`;
