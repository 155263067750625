import styled from "styled-components";

export const SResponsiveVideo = styled.div`
    padding-bottom: 56.25%;

    iframe {
        left: 50%;
        top: 50%;
        height: 100%;
        width: 100%;
        transform: translate(-50%, -50%);
        position: absolute;
    }
`;