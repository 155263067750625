import styled from 'styled-components';
import { Heading } from 'paypo-ui/components';

import { colors, breakpoints } from '../../../styles/variables';

import { SText } from '../../atoms/Typography/Text/styles';

export const H3 = styled(Heading)`
    font-size: 2.4rem;
    line-height: 3.2rem;

    @media (min-width: ${ breakpoints.popupMedium }) {
        font-size: 3.6rem;
        line-height: 4.4rem;
    }
`;

export const ItemsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    max-width: 54rem;

    @media (min-width: ${ breakpoints.medium }) {
        max-width: 100%;
    }
`;

export const Item = styled.div`
    display: flex;
    position: relative;
    flex-grow: 1;
    padding-top: 2.4rem;
    width: 100%;

    &.one-columns {
        width: 100%;
    }

    &.two-columns {
        @media (min-width: ${ breakpoints.popupMedium }) {
            width: 50%;
        }
    }

    &.three-columns {
        @media (min-width: ${ breakpoints.medium }) {
            width: 50%;
        }

        @media (min-width: ${ breakpoints.large }) {
            width: 33%;
        }
    }

    &:last-of-type {
        min-height: 11rem;
    }

    @media (min-width: ${ breakpoints.popupMedium }) {
        position: static;
        flex-direction: column;
        margin-bottom: 0;
        padding: 2.4rem 1.2rem 0;
    }
`;

export const ItemContent = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 2;
`;

export const ItemTitle = styled.p`
    text-transform: uppercase;
    font-size: 1.2rem;
    line-height: 1.6rem;
    font-family: 'Gilroy', sans-serif;
    font-weight: 800;
    margin-bottom: 1.6rem;
    color: ${ colors.blackHighContrast };
`;

export const ItemText = styled(SText)`
    margin: 0;
    font-family: 'Gilroy', sans-serif;
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: 600;
    color: ${ colors.blackHighContrast };
`;
