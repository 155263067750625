import React from 'react';

import { SSquare } from './styles';

const SquareTemplate = ({ squareData }) => {
    return (
        <SSquare squareData={ squareData } />
    );
};

export default SquareTemplate;
