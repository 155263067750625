import styled from "styled-components";

import { breakpoints } from "../../../styles/variables";

export const SVideoSection = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 2rem;
    overflow: hidden;
    position: relative;
    margin-top: 4.8rem;

    background-color: ${({ background }) => ( background || '#fff')};

    ${({ backgroundBottom }) => backgroundBottom && `
        &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 50%;
            background-color: ${ backgroundBottom };
        }
    `}
`;

export const SVideoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 85rem;
    position: relative;
    z-index: 5;
    overflow: hidden;
    border-radius: 1.6rem;

    @media (min-width: ${ breakpoints.medium }) {
        border-radius: 2.4rem;
    }
`;
