import React from 'react';
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { SSliderWrapper } from './styles';

import StartSlideTemplate from "./StartSlideTemplate";

SwiperCore.use([Navigation]);

const TilesSlider = ({ sliderData }) => (
    <SSliderWrapper>
        <Swiper
            className="slider"
            spaceBetween={16}
            slidesPerView="auto"
            centerInsufficientSlides
            onSwiper={(swiper) => setTimeout(() => {
                swiper.update();
            }, 1000)}
            breakpoints={{
                768: { slidesPerView: 2 },
                1440: { slidesPerView: 3 },
            }}
        >
            {
                sliderData.map( slideData => (
                    <SwiperSlide
                        className="slide"
                        key={ slideData.id }
                    >
                        <StartSlideTemplate slideData={ slideData } />
                    </SwiperSlide>
                ))
            }
        </Swiper>
    </SSliderWrapper>
);

export default TilesSlider;
