import styled from "styled-components";

import { colors, breakpoints } from  '../../../styles/variables';

export const ItemsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    max-width: 100%;
    flex-wrap: wrap;
    align-items: center;
`;

export const Item = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    padding: 2rem 1rem 0;
    box-sizing: border-box;
    width: 100%;

    @media (min-width: ${ breakpoints.popupMedium }) {
        width: 50%;
    }
`;

export const IconContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    .svg-icon {
        height: 6.4rem;
        margin-bottom: 1.5rem;
        box-sizing: border-box;
    }
`;

export const ItemDescription = styled.p`
    width: 100%;
    max-width: 100%;
    text-align: center;
    font-family: 'Gilroy', sans-serif;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 2rem;
    color: ${ colors.blackHighContrast };
    margin: 0;

    span {
        font-weight: 800;
    }
`;

export const SlideAdditionalText = styled.p`
    font-family: 'Gilroy', sans-serif;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: ${ colors.blackLowContrast };
    margin: 0;
    padding-top: 2.4rem;
    letter-spacing: -.03rem;

    &:first-of-type {
        padding-top: 3.2rem;
    }

    a {
        color: ${ colors.paypoGreen600 };
        text-decoration: none;
    }
`;
