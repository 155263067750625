import React from 'react';

import AccordionContainer from './AccordionContainer';
import { AccordionProvider } from './AccordionContext';

const Accordion = ({
    concurrent: isConcurrentMode = false,
    children,
}) => (
    <AccordionProvider isConcurrentMode={ isConcurrentMode }>
        <AccordionContainer>
            { children }
        </AccordionContainer>
    </AccordionProvider>
);

export default Accordion;
