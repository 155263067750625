import React from 'react';

import * as S from './styles';

import Section from '../../layout/Section';
import TileItem from './TileItem';

const SectionPopupBigTiles = ({
    config,
    background = '',
    isColorSectionAbove = false,
    isColorSectionBelow = false,
}) => {
    const {
        title,
        id,
        tileList,
        forwardedRef,
        navigationRef,
    } = config;

    return (
        <Section
            background={ background }
            forwardedRef={ forwardedRef }
            navigationRef={ navigationRef }
            id={ id }
            isColorSectionAbove={ isColorSectionAbove }
            isColorSectionBelow={ isColorSectionBelow }
            popupSection
        >
            <S.H3>
                { title }
            </S.H3>

            <S.TilesContainer>
                {
                    tileList.map( tile => (
                        <TileItem
                            key={ tile.id }
                            tileData={ tile }
                        />
                    ))
                }
            </S.TilesContainer>
        </Section>
    );
};

export default SectionPopupBigTiles;
