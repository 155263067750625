import React, { useState, createContext } from 'react';

export const AccordionContext = createContext();
export const AccordionExpandContext = createContext();

export const AccordionProvider = ({ isConcurrentMode, children }) => {
    const [openAccordionChild, setOpenAccordionChild] = useState();
    const [defaultExpandedChildren, setDefaultExpandedChildren] = useState([]);
    
    return (
        <AccordionContext.Provider value={[
            openAccordionChild,
            setOpenAccordionChild,
            isConcurrentMode
        ]}>
            <AccordionExpandContext.Provider value={[
                defaultExpandedChildren,
                setDefaultExpandedChildren,
            ]}>
                { children }
            </AccordionExpandContext.Provider>
        </AccordionContext.Provider>
    );
};
