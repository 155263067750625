import React, { useEffect, useContext } from 'react';
import { Heading } from 'paypo-ui/components'

import * as S from './styles';

import { checkAppearanceInViewport } from '../../../utils/checkAppearanceInViewport';

import { SectionAppearanceContext } from '../SectionContext';
import Section from '../../layout/Section';
import HeroGraphic from './HeroGraphic';

const SectionHeroStart = ({
    config,
    isColorSectionAbove = false,
    isColorSectionBelow = false,
}) => {
    const {
        title,
        id,
        subtitle,
        marketingText,
        promoTextColor,
        promoTextSize,
        content,
        forwardedRef,
        navigationRef,
        heroGraphic,
    } = config;

    const [ activeSection, setActiveSection ] = useContext(SectionAppearanceContext);

    const handleSetCurrentSection = sectionId => {
        if (sectionId !== activeSection) {
            setActiveSection(sectionId);
        }
    };

    useEffect(() => {
        checkAppearanceInViewport(
            navigationRef,
            handleSetCurrentSection
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigationRef]);

    return (
        <Section
            id={ id }
            forwardedRef={ forwardedRef }
            navigationRef={ navigationRef }
            isColorSectionAbove={ isColorSectionAbove }
            isColorSectionBelow={ isColorSectionBelow }
        >
            <S.HeroContainer>
                <S.HeroContent>
                    <Heading
                        variant='H1'
                        styleVariant='XL'
                        className='mt-0-xl mb-xs-s rwd-md-center-left'
                    >
                        { title }
                    </Heading>

                    {
                        subtitle && (
                            <S.HeroSubheader>
                                { subtitle }
                            </S.HeroSubheader>
                        )
                    }

                    {
                        !!marketingText && (
                            <S.HeroPromoText
                                dangerouslySetInnerHTML={{ __html: marketingText }}
                                promoTextColor={ promoTextColor }
                                size={ promoTextSize }
                            />
                        )
                    }
                    
                    {
                        content.map(({ text }) => (
                            <S.HeroText key={ text }>
                                { text }
                            </S.HeroText>
                        ))
                    }
                </S.HeroContent>
                <HeroGraphic graphicName={ heroGraphic } />
            </S.HeroContainer>
        </Section>
    );
};

export default SectionHeroStart;
