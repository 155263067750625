import React, { useContext, useRef, useEffect } from 'react';

import { SAccordionContainer } from './styles';
import { AccordionExpandContext } from './AccordionContext';

const AccordionContainer = ({ children }) => {
    const isFirstWarn = useRef(true);
    const [ defaultExpandedChildren ] = useContext(AccordionExpandContext);

    useEffect(() => {
        if (defaultExpandedChildren.length > 1 && isFirstWarn.current) {
            console.warn('If you turn on concurrent mode in Accordion component, you can set only one Accordion Card as expanded by default');
            isFirstWarn.current = false;
        }
    }, [defaultExpandedChildren, defaultExpandedChildren.length]);

    return (
        <SAccordionContainer>
            { children }
        </SAccordionContainer>
    );
};

export default AccordionContainer;
