import React, { useState, useContext, useEffect, useRef } from 'react';
import { AccordionContext } from './AccordionContext';
import { AccordionExpandContext } from './AccordionContext';
import CollapsingCard from '../CollapsingCard';

export const AccordionCard = ({
    id,
    header,
    expanded,
    children
}) => {
    const [isCardExpanded, setIsCardExpanded] = useState(false);
    const [currentCardId, setCurrentCardId] = useState();
    const isFirstRender = useRef(true);

    const [
        openAccordionChild,
        setOpenAccordionChild,
        isConcurrentMode
    ] = useContext(AccordionContext);

    const [
        defaultExpandedChildren,
        setDefaultExpandedChildren
    ] = useContext(AccordionExpandContext);

    const interceptCardId = cardId => setCurrentCardId(cardId);
    const interceptOpenCardId = cardId => setOpenAccordionChild(cardId);

    useEffect(() => {
        if (!isFirstRender.current) {
            if (isConcurrentMode) {
                setIsCardExpanded(currentCardId === openAccordionChild);
            } else if (currentCardId === openAccordionChild) {  
                setIsCardExpanded(!isCardExpanded);
            } else if (!openAccordionChild) {
                setIsCardExpanded(false);
            }
        }

        isFirstRender.current = false;
    }, [
        openAccordionChild,
        currentCardId,
        isCardExpanded,
        isConcurrentMode,
        expanded,
    ]);

    useEffect(() => {
        const isIdOfDefaultExpandedCard = expanded && isConcurrentMode && (!!currentCardId);
        const isCurrentCardAdded = defaultExpandedChildren.includes(currentCardId);

        if (isIdOfDefaultExpandedCard && !isCurrentCardAdded) {
            setDefaultExpandedChildren(prevChildren => [...prevChildren, currentCardId]);
        }
    }, [
        defaultExpandedChildren,
        defaultExpandedChildren.length,
        setDefaultExpandedChildren,
        isConcurrentMode,
        currentCardId,
        expanded
    ]);

    return (
        <CollapsingCard
            id={ id }
            header={ header }
            transmitCardId={ interceptCardId }
            transmitOpenCardId={ interceptOpenCardId }
            expanded={ isCardExpanded }
            isConcurrentMode={ isConcurrentMode }
            inAccordion
        >
            { children }
        </CollapsingCard>
    );
};
