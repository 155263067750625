import styled from "styled-components";
import { colors, breakpoints } from "../../../../styles/variables";

export const SH4 = styled.h4`
    font-family: 'Gilroy', sans-serif;
    font-weight: 800;
    font-size: 2.0rem;
    line-height: 2.8rem;
    position: relative;
    margin: 0 0 1.6rem;
    z-index: 1;
    letter-spacing: -.1rem;

    color: ${
        ({ colorGreen }) => colorGreen ? `${ colors.paypoGreen600 }` : `${ colors.black }`
    };

    text-align: ${
        ({ textAlign }) => textAlign
    };

    @media (min-width: ${ breakpoints.medium }) {
        font-size: 2.4rem;
        line-height: 3.2rem;
    }
`;
