import React from 'react';
import { Button, Heading } from 'paypo-ui/components';

import { SHeaderContainer } from './styles';
import * as S from './styles';

import { Text } from '../../atoms/Typography';
import Section from '../../layout/Section';
import SquareGenerator from '../../molecules/SquareGenerator';
import TilesSlider from './TilesSlider';

const SectionInlineTiles = ({
    config,
    background,
    isColorSectionAbove = false,
    isColorSectionBelow = false,
}) => {
    const {
        title,
        description,
        id,
        cta,
        tileList,
        additionalText,
        squares,
        forwardedRef,
        navigationRef,
    } = config;

    return (
        <Section
            background={ background }
            forwardedRef={ forwardedRef }
            navigationRef={ navigationRef }
            id={ id }
            isColorSectionAbove={ isColorSectionAbove }
            isColorSectionBelow={ isColorSectionBelow }
        >
            <SHeaderContainer>
                <Heading>
                    { title }
                </Heading>

                {
                    !!description && (
                        <Text>
                            { description }
                        </Text>
                    )
                }
            </SHeaderContainer>

            {
                tileList && (
                    <TilesSlider
                        sliderData={ tileList }
                    />
                )
            }

            {
                additionalText ? (
                    additionalText.map(({ text }) => (
                        <S.SlideAdditionalText dangerouslySetInnerHTML={ { __html: text } } />
                    ))
                ) : null
            }

            {
                !!cta && (
                    <Button
                        type={ cta.typo }
                        linkTo={ cta.link }
                        target="_blank"
                        variant="secondary"
                    >
                        { cta.text }
                    </Button>
                )
            }

            {
                !!squares && <SquareGenerator squares={ squares }/>
            }
        </Section>
    );
};

export default SectionInlineTiles;
