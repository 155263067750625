import styled from 'styled-components';

import { colors, breakpoints } from '../../../styles/variables';
import { SText } from '../../atoms/Typography/Text/styles';

export const SItemsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    max-width: 54rem;
    flex-direction: row;
    margin-bottom: -5rem;

    @media (min-width: ${ breakpoints.medium }) {
        max-width: 100%;
    }
`;

export const SItem = styled.div`
    display: flex;
    position: relative;
    flex-grow: 1;
    margin-bottom: 2rem;
    padding: 0 1rem 5rem;
    width: 100%;

    &.one-columns {
        width: 100%;
    }

    &.two-columns {
        @media (min-width: ${ breakpoints.medium }) {
            width: 50%;
        }
    }

    &.three-columns {
        @media (min-width: ${ breakpoints.medium }) {
            width: 50%;
        }

        @media (min-width: ${ breakpoints.large }) {
            width: 33%;
        }
    }

    @media (min-width: ${ breakpoints.medium }) {
        position: static;
        margin-bottom: 0;
    }
`;

export const SItemNumber = styled.p`
    display: flex;
    justify-content: center;
    flex-shrink: 0;
    position: absolute;
    width: 10rem;
    color: ${ colors.paypoLigthtGrey };
    font-family: 'Gilroy', sans-serif;
    font-weight: 800;
    font-size: 13.2rem;
    line-height: 9.2rem;
    margin: 0;
    right: 0;
    z-index: 2;

    @media (min-width: ${ breakpoints.medium }) {
        position: static;
        width: 13.6rem;
    }
`;

export const SItemContent = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 2;

    @media (min-width: ${ breakpoints.big }) {
        padding: 0 0 0 2.4rem;
    }
`;

export const SItemTitle = styled.p`
    text-transform: uppercase;
    font-size: 1.2rem;
    line-height: 1rem;
    font-family: 'Gilroy', sans-serif;
    font-weight: 800;
    margin-botton: 1.6rem;
    color: ${ colors.blackHighContrast };
`;

export const SItemText = styled(SText)`
    margin: 0;
    font-family: 'Gilroy', sans-serif;
    font-weight: 600;
    color: ${ colors.blackHighContrast };
`;
