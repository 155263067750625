import React from 'react';
import { Heading } from 'paypo-ui/components';

import {
    SHeaderWrapper,
    SAccordionContainer,
    SCardContent,
} from './styles';

import Section from '../../layout/Section';
import Accordion, { AccordionCard } from '../../molecules/Accordion';
import SquareGenerator from '../../molecules/SquareGenerator';

const SectionAccordion = ({
    config,
    background = false,
    isColorSectionAbove = false,
    isColorSectionBelow = false,
}) => {
    const {
        title,
        id,
        content,
        squares,
        forwardedRef,
        navigationRef,
    } = config;

    return (
        <Section
            background={ background }
            forwardedRef={ forwardedRef }
            navigationRef={ navigationRef }
            id={ id }
            isColorSectionAbove={ isColorSectionAbove }
            isColorSectionBelow={ isColorSectionBelow }
        >
            <SHeaderWrapper>
                <Heading
                    variant='H2'
                    className='mb-m-xl'
                >
                    { title }
                </Heading>
            </SHeaderWrapper>
            <SAccordionContainer>
                <Accordion concurrent>
                    {
                        content.map(({
                            id,
                            title,
                            text,
                            isExpanded = false,
                        }) => (
                            <AccordionCard
                                header={ title }
                                expanded={ isExpanded }
                                key={ id }
                                id={ id }
                            >
                                <SCardContent>
                                    { text }
                                </SCardContent>
                            </AccordionCard>
                        ))
                    }
                </Accordion>
            </SAccordionContainer>
            {
                !!squares && <SquareGenerator squares={ squares }/>
            }
        </Section>
    );
};

export default SectionAccordion;
