import React from 'react';
import { SText } from './styles';

const Text = ({ children, align = "center" }) => {
    return (
        <SText
            align={ align }
        >
            { children }
        </SText>
    );
};

export default Text;
