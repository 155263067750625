import React from 'react';

import * as S from './styles';

const OrderedItem = ({
    itemData,
    itemColumnClass,
}) => {
    const { id, title, text } = itemData;

    return (
        <S.Item className={ itemColumnClass }>
            <S.ItemContent>
                <S.ItemTitle>
                    { title }
                </S.ItemTitle>
                
                <S.ItemText>
                    { text }
                </S.ItemText>
            </S.ItemContent>
        </S.Item>
    );
};

export default OrderedItem;
