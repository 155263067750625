import styled from "styled-components";

import { colors } from '../../../../styles/variables';

export const SText = styled.p`
    font-family: "Gilroy", sans-serif;
    font-size: 1.6rem;
    line-height: 2.4rem;
    letter-spacing: -.03rem;
    text-align: ${({ align }) => align };
    font-weight: 400;
    color: ${ colors.blackHighContrast };
`;
