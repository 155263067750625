import styled from "styled-components";

import { breakpoints, colors } from '../../../styles/variables';

export const SNumberList = styled.ul`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 50px;
    text-align: center;
    list-style: none;
    margin: 0;

    @media (min-width: ${ breakpoints.medium }) {
        grid-template-columns: repeat(2, auto);
        grid-gap: 50px 0px;
    }

    @media (min-width: ${ breakpoints.big }) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: ${ breakpoints.large }) {
        grid-gap: 50px 10px;
        grid-template-columns: repeat(3, 1fr);
    }
`;

export const SNumberItem = styled.li`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 14rem;
    max-width: 38rem;
    width: 100%;
`;

export const SNumberDescription = styled.p`
    max-width: 30rem;
    font-family: 'Gilroy', sans-serif;
    font-weight: 800;
    font-size: 2.4rem;
    text-align: center;
    width: 100%;
    max-width: 38rem;
    line-height: 3.2rem;
    padding: 0 1rem;
    color: ${ colors.black };

    @media (min-width: ${ breakpoints.medium }) {
        padding: ${
            ({ isPaddingSmall }) => isPaddingSmall ? '0 1rem' : '0 3rem'
        };
    }
`;
