import React from 'react';

import {
    SItem,
    SItemNumber,
    SItemContent,
    SItemTitle,
    SItemText,
} from './styles';

const OrderedItem = ({
    itemData,
    itemColumnClass,
}) => {
    const { id, title, text } = itemData;

    return (
        <SItem className={ itemColumnClass }>
            <SItemNumber>
                { id }
            </SItemNumber>
            <SItemContent>
                <SItemTitle>
                    { title }
                </SItemTitle>
                <SItemText>
                    { text }
                </SItemText>
            </SItemContent>
        </SItem>
    );
};

export default OrderedItem;
