import styled from "styled-components";

import { colors, breakpoints } from '../../../../styles/variables';

export const SH2 = styled.h2`
    font-family: 'Gilroy', sans-serif;
    font-weight: 800;
    font-size: 3.6rem;
    position: relative;
    margin: 0 3rem 3.2rem;
    text-align: center;
    z-index: 1;

    padding-bottom: ${({ titleUnderline }) =>
        ( titleUnderline ? '13px' : 'none')}
    ;

    span {
        box-shadow: ${({ titleUnderline }) =>
            ( titleUnderline ? `inset 0 0 white, inset 0 -25px ${colors.pinkAmour}` : 'none')}
        ;
    }

    @media (min-width: ${ breakpoints.medium }) {
        font-size: 4.2rem;
        margin: 0 3rem 6.4rem;
    }

    @media (min-width: ${ breakpoints.big }) {
        font-size: 6rem;
        line-height: 7.2rem;
    }
`;
