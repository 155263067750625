import styled from "styled-components";

import { breakpoints } from '../../../styles/variables';

export const SSection = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    overflow: hidden;
    position: relative;
    background-color: ${({ background }) => ( background || '#fff')};

    // popup.paypo.pl custom section padding

    ${
        ({ popupSection, isDoublePaddingTop, isDoublePaddingBottom }) => !!popupSection ? `
            padding: 3.2rem 2.4rem;

            @media (min-width: ${ breakpoints.popupMedium }) {
                padding: 3.2rem;
            }
        ` : `
            padding: ${
                isDoublePaddingTop ? '6.4rem' : '3.2rem'
            } 2rem ${
                isDoublePaddingBottom ? '6.4rem' : '3.2rem'
            };

            @media (min-width: ${ breakpoints.medium }) {
                padding: ${
                    isDoublePaddingTop ? '9.6rem' : '4.8rem'
                } 3rem ${
                    isDoublePaddingBottom ? '9.6rem' : '4.8rem'
                };
            }
        `
    }
`;

export const SSectionContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: ${({ alignItems }) => alignItems };
    width: 100%;
    max-width: 114rem;
    z-index: 5;
`;

export const SReferenceAnchor = styled.div`
    position: absolute;
    width: 100%;
    height: 0;
    left: 0;
    top: -10rem;
`;
