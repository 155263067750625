import React from 'react';
import { Button, Heading } from 'paypo-ui/components';

import * as S from './styles';

import Section from '../../layout/Section';
import SquareGenerator from '../../molecules/SquareGenerator';
import IconItem from './IconItem';

const SectionIconItems = ({
    config,
    background = '',
    columns = 4,
    isColorSectionAbove = false,
    isColorSectionBelow = false,
}) => {
    const {
        title,
        id,
        content,
        additionalText,
        cta,
        handleCta,
        squares,
        forwardedRef,
        navigationRef,
    } = config;

    const handleColumnClass = columns => {
        switch (columns) {
            case 2:
                return 'two-columns';
            case 3:
                return 'three-columns';
            case 4:
            default:
                return 'four-columns';
        }
    };

    return (
        <Section
            background={ background }
            forwardedRef={ forwardedRef }
            navigationRef={ navigationRef }
            id={ id }
            isColorSectionAbove={ isColorSectionAbove }
            isColorSectionBelow={ isColorSectionBelow }
        >
            <Heading className='mb-m-xl'>
                { title }
            </Heading>

            <S.ItemsContainer>
                {
                    content.map(({
                        Icon,
                        description
                    }, index)=> (
                        <IconItem
                            key={ index }
                            Icon={ Icon }
                            description={ description }
                            itemColumnClass={ handleColumnClass(columns) }
                        />
                    ))
                }
            </S.ItemsContainer>

            {
                additionalText ? (
                    additionalText.map(({ text }) => (
                        <S.AdditionalText dangerouslySetInnerHTML={ { __html: text } } />
                    ))
                ) : null
            }

            {
                cta && (
                    <Button onClick={ handleCta }>
                        { cta }
                    </Button>
                )
            }

            {
                !!squares && <SquareGenerator squares={ squares }/>
            }
        </Section>
    );
};

export default SectionIconItems;
